// Here you can add other styles
$primary: #82A55A;
$light: transparent;
$link: #000000;
$seleccionado: #3399ff !default;
$cargado: #82A55A !default;
$disponible: #636f83 !default;
$sidebarBase: #82A55A;
$sidebarHover: #2e5f46;
$btnActualizar: #3399ff;
$btnActualizarHover: #2282e2;
$btnNuevo: #82A55A;
$btnNuevoHover: #6e9445;
$btnModificar: #f9b115;
$btnModificarHover: #dd9c11;
$btnBorrar: #f05523;
$btnBorrarHover: #da4b1c;
$btnGuardar: #82A55A;
$btnGuardarHover: #6e9445;
$btnImprimir: #636f83;
$btnImprimirHover: #79808f;
$btnPublicar: #3399ff;
$btnPublicarHover: #2282e2;
$btnRelacionar: #ebedef;
$btnRelacionarHover: #d6dade;
$btnBuscar: #636f83;
$btnBuscarHover: #79808f;
$btnCancelar: #f05523;
$btnCancelarHover: #da4b1c;
$btnCerrar: #ebedef;
$btnCerrarHover: #d6dade;
$btnAlt: #636f83;
$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$letraBlanca: #FFFFFF;

// Manual de marca - 2024
$verdeER01: #B6D938;
$verdeER02: #64B44B;
$verdeER03: #93C402;
$verdeER04: #82A55A;
$grisER: #4B5A6A;
$beigeER: #F0F0E6;

$celesteER01: #5AB9CD;
$celesteER02: #9BBDDF;
$celesteER03: #4C86C5;
$azulER: #2B4C6F;
$negroER: #3C3C3C;
$blancoER: #FFFFFF;
// Manual de marca - 2024

.letraColorBlancoER{
  color:$blancoER;
}

.listaOrganismos:hover {
  background-color: $beigeER !important;
  color: $negroER;
}

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'seleccionada': $seleccionado,
    'cargada': $cargado,
    'disponible': $disponible,
  ),
  $theme-colors
);

.toastError > .toast-header {
  background-color: #e55353 !important;
  color: white;
  border-bottom: 0px;
  font-weight: bolder;
}
.toastError > .toast-body {
  background-color: #e5535380 !important;
  color: white;
  font-weight: bolder;
  vertical-align: middle;
}
.toastSuccess > .toast-header {
  background-color: #2eb85c !important;
  color: white;
  border-bottom: 0px;
  font-weight: bolder;
}
.toastSuccess > .toast-body {
  background-color: #2eb85c80 !important;
  color: white;
  font-weight: bolder;
  vertical-align: middle;
}
.btn-primary {
  color: #ffffff !important;
}
.container-er {
  @media screen and (max-width: $xl) {
    width: 100%;
  }
  @media screen and (min-width: $xl) {
    width: 85%;
  }
}
.enca-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0px;
}
.pie-texto {
  font-size: 20px;
  @media screen and (max-width: $sm) {
    // text-align: center;
    text-align: left;
    //padding-bottom: 1.5rem;
    padding-left: -1px;
  }
  @media screen and (min-width: $sm) {
    //text-align: left;
    padding-left: -1px;
  }
}
.pie-logo {
  @media screen and (max-width: $sm) {
    justify-content: right;
  }
  @media screen and (min-width: $sm) {
    justify-content: flex-end;
  }
}

.logo-header-interior-i {
  @media screen and (max-width: $sm) {
    justify-content: left;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    width: 50%;
  }
  @media screen and (min-width: $sm) {
    justify-content: flex-start;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 50%;
  }
}

.espacio-abajo {
  padding-bottom: 10px;
}

.espacio-abajo2 {
  padding-bottom: 5px;
}

.espacio-izq2 {
  padding-left: 2px;
}

.espacio-derecho {
  float: right;
}

.logo-header-interior-d {
  @media screen and (max-width: $sm) {
    justify-content: end;
    width: 100%;
  }
  @media screen and (min-width: $sm) {
    justify-content: end;
    width: 100%;
  }
}

.logo-header-redes-d {
  @media screen and (max-width: $sm) {
    text-align: right;
    margin-top: 1rem;
    //width: 50%;
  }
  @media screen and (min-width: $sm) {
    text-align: right;
    margin-top: 1rem;
    //width: 50%;
  }
}
.logo-header-redes-c {
  @media screen and (max-width: $sm) {
    text-align: center;
    //margin-top: 1rem;
    //width: 50%;
  }
  @media screen and (min-width: $sm) {
    text-align: center;
    //margin-top: 1rem;
    //width: 50%;
  }
}

.socalo {
  background-color: $grisER !important;
  height: 2rem;
  color: $blancoER;
  text-align: center;
  padding-top: 0.25rem;
  font-size: 0.8rem;
}

.margenizq {
  padding-left: 20rem !important;
  margin-top: 4rem;
}

.margenizq1 {
  padding-left: 8rem !important;
}
.margenizqpie {
  padding-right: 1rem !important;
}

.margenderpie {
  @media screen and (min-width: $sm) {
    margin-left: 7rem !important;
  }
  @media screen and (min-width: $md) {
    width: 100%;
  }
  @media screen and (max-width: $md) {
    width: 100%;
  }
}

.link-blanco {
  color: #fff;
}

.t-utiles {
  background-color: $verdeER04 !important;
  color: $blancoER !important;
  min-height: 10rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (max-width: $sm) {
    //padding-left: 1rem !important;
  }
}

.t-u-numero {
  a {
    text-decoration: underline;
    color: $blancoER;
    @media screen and (max-width: $sm) {
      font-size: 1.25rem;
    }
    @media screen and (min-width: $sm) {
      font-size: 1.75rem;
    }
    :active {
      color: $blancoER !important;
    }
    :hover {
      cursor: pointer;
      color: $blancoER !important;
    }
    :focus {
      color: $blancoER !important;
    }
  }
}

.ir-arriba {
  display: none;
  //padding:20px;
  //background:#024959;
  font-size: 20px;
  //color:#fff;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  //right:20px;
}

.margen-cel {
  @media screen and (max-width: $sm) {
    margin-left: -2rem;
  }
}

.complementos-color {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  color: #a1a1a1;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 17px;
}

.audiosest{
  border-style: solid; 
  border-width: 1px;
  border-radius: 0;
  background-color: #000000;
  width: 40px;  
  height: 90px;
}


.t-u-numero-blanco {
  text-decoration: underline;
  color: #ffffff !important;
  @media screen and (max-width: $sm) {
    font-size: 1rem;
  }
  @media screen and (min-width: $sm) {
    font-size: 1rem;
  }
  :active {
    color: #ffffff !important;
  }
  :hover {
    cursor: pointer;
    color: #ffffff !important;
  }
  :focus {
    color: #ffffff !important;
  }
}
.t-u-texto {
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.barra-busqueda-sup {
  background-color: $primary !important;
  color: #000000 !important;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
@mixin gestion($color) {
  border-radius: 1rem;
  @media screen and (max-width: $sm) {
    border-top: transparent;
    border-bottom: transparent;
    border-radius: 0.25rem;
  }
  .card-header {
    color: #fff;
    background-color: $color;
    font-size: 1.5rem;
    font-weight: 600;
    @media screen and (min-width: $md) {
      height: 6rem;
    }
    @media screen and (max-width: $sm) {
      font-size: 1.2rem;
      padding: 0.5rem 1rem;
    }
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .card-body {
    @media screen and (min-width: $md) {
      //height: 7rem;
      height: 100%;
      min-height: 8.5rem;
      padding: 0rem !important;
      h5 {
        font-size: 18px;
      }
    }
    @media screen and (max-width: $md) {
      //height: 7rem;
      height: 100%;
      min-height: 4.5rem;
      padding: 0rem !important;
      h5 {
        font-size: 18px;
      }
    }
  }
  .card-footer {
    background-color: $color;
    @media screen and (max-width: $sm) {
      padding: 0.3rem 1rem;
    }
  }
}

.gestion-1 {
  @include gestion(#99262c);
}
.gestion-2 {
  @include gestion(#82A55A);
}
.gestion-3 {
  @include gestion(#f05523);
}
.gestion-4 {
  @include gestion(#2a8ec1);
}
.gestion-5 {
  @include gestion(#99262c);
}
.gestion-6 {
  @include gestion(#82A55A);
}
.gestion-7 {
  @include gestion(#f05523);
}
.gestion-8 {
  @include gestion(#2a8ec1);
}
.carousel-imagen {
  position: absolute;
  left: -100%;
  right: -100%;
  top: -100%;
  bottom: -100%;
  object-fit: cover;
  margin: auto;
  min-height: 100%;
  min-width: 100%;
}
.carousel-cargando {
  @media screen and (min-width: $md) {
    width: 100%;
    height: 25.677%;
    min-height: 493px;
    overflow: hidden;
    position: relative;
  }
  @media screen and (max-width: $md) {
    width: 100%;
    height: 25.677%;
    min-height: 200px;
    overflow: hidden;
    position: relative;
  }
}
.carousel-is-loading {
  .image {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  .image {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    aspect-ratio: 515/132;
  }
}
.carousel-spinner {
  @media screen and (min-width: $md) {
    right: 50%;
    left: 50%;
    top: 45%;
  }
  @media screen and (max-width: $md) {
    // right: 389.45px;
    left: 45vw;
    top: 45%;
  }
  display: block;
  position: absolute;
  color: #fff;
}
.carousel-mensaje {
  @media screen and (min-width: $md) {
    right: 20%;
    left: 20%;
    top: 30%;
    .texto1 {
      width: 100%;
      color: #fff;
      font-size: 1.25rem;
      min-height: 30px;
    }
    .texto2 {
      width: 100%;
      color: #fff;
      font-size: 1.75rem;
      font-weight: 600;
      margin-bottom: 1.25rem;
      min-height: 45px;
    }
    .btn {
      color: #fff;
      border-color: #fff;
      font-size: 1rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
  }
  @media screen and (max-width: $md) {
    right: 12vw;
    left: 12vw;
    top: 15%;
    height: 65%;
    max-height: 65%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    div {
      width: 100%;
      .texto1 {
        width: 100%;
        color: #fff;
        font-size: 0.75rem;
        line-height: 0.75rem;
        margin-bottom: 0.25rem;
        min-height: 30px;
      }
      .texto2 {
        width: 100%;
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
        line-height: 1rem;
        min-height: 45px;
      }
    }
    .btn {
      color: #fff;
      border-color: #fff;
      font-size: 0.75rem;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
  }
  display: block;
  position: absolute;
}
.hover-click:hover {
  cursor: pointer;
}

.hover-click-interes:hover {
  cursor: pointer;
  color: $primary;
}
.filtro-carousel {
  height: 100%;
  width: 100%;
  background-color: #0000004f;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0rem;
}
.tra-ser {
  height: auto;
  @media screen and (min-width: $md) {
    min-height: 8.5rem;
  }
  cursor: pointer;
  height: 100%;
  .row {
    height: 100%;
  }
}
.tra-ser2 {
  height: auto;
  @media screen and (min-width: $md) {
    min-height: 5rem;
  }
  cursor: pointer;
  height: 100%;
  .row {
    height: 100%;
  }
}
.card-organismo {
  height: auto;
  @media screen and (min-width: $md) {
    min-height: 8.5rem;
  }
  height: 100%;
  .row {
    height: 100%;
  }
}
.centrar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: right;
  @media screen and (max-width: $xl) {
    visibility: visible;
  }
}

.centrar-btns-home {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (max-width: $xl) {
    visibility: visible;
  }
  text-align: left;
}

.icono-cel {
  @media screen and (max-width: $xl) {
    font-size: small;
  }
}

//queda texto en una sola linea
.mas-noticias {
  @media screen and (max-width: $sm) {
    font-size: 0.80rem !important;
  }

  @media screen and (max-width: $xs) {
    font-size: 0.75rem !important;
  }
}

.imagen-input {
  background-color: #ffffff;
}

.justificar {
  @media screen and (max-width: $xl) {
    text-align: justify;
  }
}
.menu-principal {
  font-size: 1rem !important;
  color: #000000 !important;
  font-weight: 200 !important;
  display: inline-block;
}

.menu-principal:hover {
  background-size: 2px;
  text-decoration: underline;
}

.text-d-underline {
  text-decoration: underline;
}

.nav-link.active {
  font-weight: $sidebar-nav-link-active-weight;
}

.nav-cel {
  list-style-type: disc;
  display: revert;
}

.nav-group-items {
  margin-block-start: 0rem;
  margin-block-end: 0rem;
  padding-inline-start: 1rem;
  height: auto !important;
}

.nav-elecciones {
  color: #28A6D1 !important;
  font-weight: bold !important;
  .numero {
    font-weight: 200 !important;
  }
}

.form-img {
  @media screen and (min-width: $md) {
    width: 70%;
  }
  @media screen and (max-width: $md) {
    width: 100%;
  }
}

.form-titulo {
  text-transform: initial;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
}

.form-titulo-propaganda {
  text-transform: initial;
  font-weight: 600;
  text-align: center;
  @media screen and (min-width: $md) {
    font-size: 30px;
  }
  @media screen and (max-width: $md) {
    font-size: 20px;
  }
}